/* eslint-disable @typescript-eslint/ban-types */

import React, { FC } from 'react';
import { IGridCard } from './GridSingleCard';

import './Cards.css';
import GridSingleCard from './GridSingleCard';
import { PaginationTheme } from '../globalExports';
import { Pagination, TotalCountComponent } from '../TableDashboard/TablePagination';
import { formatNumberWithCommas, useEffectOnce } from '../globalHooks';
import { Box, ThemeProvider, Typography } from '@mui/material';
import TablePageCount from '../TableDashboard/TablePageCount';
import InfoIcon from '@mui/icons-material/Info';
import TablePageCountConfNoti from '../TableDashboard/TablePageCountConfNoti';

export interface IRuleEngineGrid {
    cardClassName?: string;
    ruleDetail: IGridCard[];
    screen?: string;
    handleToggleChange: Function;
    handleDelete?: Function;
    handleCardClick?: Function;
    handleCardClickRuleEngine?: Function;
    pageNumber: number;
    tablePageSize: number;
    countText: string;
    handlePaginationChange: Function;
    handlePageCountChange: Function;
    totalCount: number;
    listLayoutCallBack?: Function;
   
}

const RuleEngineGrid: FC<IRuleEngineGrid> = (props) => {

    const {
        ruleDetail,
        screen,
        handleToggleChange,
        handleDelete,
        handleCardClick,
        cardClassName,
        pageNumber,
        tablePageSize,
        countText,
        handlePaginationChange,
        totalCount,
        handlePageCountChange,
        handleCardClickRuleEngine,
        listLayoutCallBack,
       
    } = props;

    // const [isLoading,setIsLoading] = useState(true)

    useEffectOnce(() => {
        listLayoutCallBack?.(pageNumber, tablePageSize)
           
    });

    return (
        <div className={'rule_engine_grid__container_wrapper ' + cardClassName}>
            
                <>
                    <div className={'rule_engine_grid__container'}>
                        <div className={'rule_engine_all_rules_list ' + cardClassName}>
                            {ruleDetail?.length > 0 ? (
                                ruleDetail.map((data, key) => (
                                    <div key={key} className="rule_engine_single_card">
                                        <GridSingleCard
                                            cardData={data}
                                            screen={screen}
                                            handleToggleChange={handleToggleChange}
                                            handleDelete={handleDelete}
                                            cardClassName={cardClassName}
                                            handleSingleCardClick={handleCardClick}
                                            handleSingleCardClickRuleEngine={handleCardClickRuleEngine}
                                        />
                                    </div>
                                ))
                            ) : (
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="50vh"
                                    textAlign="center"
                                    margin="auto"
                                >
                                    <InfoIcon sx={{ fontSize: 48, color: 'gray' }} />
                                    <Typography variant="h6" sx={{ color: 'gray', mt: 2 }}>
                                        No Rules Available
                                    </Typography>
                                </Box>
                            )}
                        </div>
                    </div>
                    <div className="grid_card_pagination_footer">
                        <ThemeProvider theme={PaginationTheme}>
                            <div className="table__footer__wrap-paginated-grid">
                                <div className="table__footer__wrap-paginated-grid-right">
                                    <TotalCountComponent
                                        totalCount={formatNumberWithCommas(props?.totalCount)}
                                        totalCountText={countText}
                                    />
                                </div>

                                <div className="table__footer__wrap-paginated-grid-right">
                                    {screen !== 'configureNotificaion' ? (
                                        <TablePageCount
                                            onCountChange={(count) => handlePageCountChange?.(count)}
                                        />
                                    ) : (
                                        <TablePageCountConfNoti
                                            onCountChange={(count) => handlePageCountChange?.(count)}
                                        />
                                    )}
                                    <Pagination
                                        count={Math.ceil(totalCount ? totalCount / (tablePageSize ?? 1) : 1)}
                                        onPageChange={(event, page) => handlePaginationChange?.(page)}
                                        rowsPerPage={tablePageSize ?? 5}
                                        page={pageNumber ?? 1}
                                    />
                                </div>
                            </div>
                        </ThemeProvider>
                    </div>
                </>
            
        </div>
    );
};

export default RuleEngineGrid;