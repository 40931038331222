/* eslint-disable @typescript-eslint/ban-types */
import React, { memo, useCallback, useEffect, useMemo, useState, } from 'react';
import './RaiseATicketPopOver.css';
import { CustomButtonGroup, CustomTextArea, SelectDropDown, useAppDispatch, useAppSelector, useEffectOnce, } from '../../../globalUtils/globalExports';
import { fetchRuleEngineRaiseATicketCategories, fetchRuleEngineRaiseATicketUrgency, getReferenceDataReducer } from '../../../rmsReduxStore/reduxExports';
import { cloneDeep, isEqual } from 'lodash';
import ExecutionPlan, { RaiseTicketAction } from './ExecutionPlan';
import { fetchRuleEngineRaiseATicketCallType } from '../../../rmsReduxStore/referenceDataRedux/referenceDataCreators';

interface RaiseATicketPopOverProps{
    handleClose : Function;
    raiseATicketCallTypeValue : string;
    raiseATicket: RaiseTicketAction | null;
    executionPlan: ExecutionPlan;
    handleUpdateExecutionPlan: Function;
    ifOrElse: string,
}

const RaiseATicketPopOver = memo(function RaiseATicketPopOver({
    handleClose,
    executionPlan,
    raiseATicket,
    handleUpdateExecutionPlan,
    ifOrElse,
    
} : RaiseATicketPopOverProps){

    const dispatch = useAppDispatch(); 
    const {ruleEngineRaiseATicketCallTypeReferenceData,ruleEngineRaiseATicketCategoryReferenceData,ruleEngineRaiseATicketUrgencyReferenceData} = useAppSelector(getReferenceDataReducer);
    const [formData,setFormData] = useState<RaiseTicketAction>({
        type: 'Raise Ticket',
        // subject: '',
        description: '',
        callType: '',
        category: '',
        subCategory: '',
        urgency: '',
    })

    const [initialFormData,setInitialFormData] = useState<RaiseTicketAction>({
        type: 'Raise Ticket',
        // subject: '',
        description: '',
        callType: '',
        category: '',
        subCategory: '',
        urgency: '',    
    });
    // const [subjectError,setSubjectError] = useState<string|null>(null);
    const [descriptionError,setDescriptionError] = useState<string|null>(null);


    useEffectOnce(()=>{
        dispatch(fetchRuleEngineRaiseATicketCategories());
        dispatch(fetchRuleEngineRaiseATicketUrgency());
        dispatch(fetchRuleEngineRaiseATicketCallType());
    })


    useEffect(()=>{
        if(raiseATicket){
            setInitialFormData(raiseATicket);
            setFormData(raiseATicket);
        } 
    },[raiseATicket])


    const disableButton = useCallback(() => {
        if(isEqual(initialFormData,formData))
            return true;
        return !(formData && formData?.callType && formData?.callType.length>0 && formData.category && formData.category.length>0 &&  formData.subCategory && formData?.subCategory.length>0 && formData.urgency && formData.urgency.length>0 &&  descriptionError === null) 
       }, [formData,descriptionError])


    const btnsList = useMemo(() => {
        return [
          {
            buttonText: 'Save',
            buttonId: 'add',
            btnClassName:  disableButton() ? 'primary__btn disabled' : 'primary__btn',
            handleClick: (): void => {
                const data = cloneDeep(formData);
                const formattedData : RaiseTicketAction = {
                    type: 'Raise Ticket',
                    // subject: data.subject,
                    description: data.description,
                    category: data.category,
                    subCategory: data.subCategory,
                    urgency: data.urgency,
                    callType: data.callType,
                }

                const executionData = cloneDeep(executionPlan);
                const remoteCommandIndex = executionData?.action?.find((executionDataItem)=> executionDataItem.flowType === ifOrElse)?.list?.findIndex((executionPlanAction) => executionPlanAction.type === 'Raise Ticket')
                if (remoteCommandIndex !== undefined && remoteCommandIndex !== -1) {
                  executionData.action[ifOrElse === 'If' ? 0 : 1].list[remoteCommandIndex] = formattedData
                } else {
                  executionData?.action?.[ifOrElse === 'If' ? 0 : 1].list?.push(formattedData) 
                }
                handleUpdateExecutionPlan(executionData);
                handleClose();
            },
            isDisabled: disableButton(),
            buttonVariant: 'filled',
          },
          {
            buttonText: 'Cancel',
            buttonId: 'cancel',
            btnClassName: 'secondary__btn',
            handleClick: (): void => {
                handleClose()
            },
            isDisabled: false,//disableCancelButton(),
            buttonVariant: 'outlined',
          },
        ];
      }, [formData,disableButton]);
      
    return(
        <>
         <div className='rule__engine__raise__a__ticket__side__popover__wrapper'>
            <div className=''>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Call Type *
                </div>
                <div>
                    <SelectDropDown
                     selectDropDownId={'rule__engine__raise__a__ticket__call__type__dropdown'} 
                     handleSelectOptionChange={(val)=>{
                        const data = cloneDeep(formData);
                        setFormData({
                            ...data,
                            callType: val?.label                        })
                     }} 
                    selectOptions={ruleEngineRaiseATicketCallTypeReferenceData ?? []}
                    selectValue={{id: formData?.callType, label: formData?.callType}}
                    />
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Category*
                </div>
                <div>
                    <SelectDropDown 
                        selectDropDownId={'rule__engine__raise__a__ticket__popover__category__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            
                            const data = cloneDeep(formData);
                            setFormData({
                                ...data,
                                category: val?.label,
                                subCategory: ''
                            })
                            
                        }} 
                        selectOptions={ruleEngineRaiseATicketCategoryReferenceData ?? []}
                        selectValue={{id : formData?.category, label : formData?.category,}}
                    />
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Sub-Category*
                </div>
                <div>
                    <SelectDropDown
                        selectDropDownId={'rule__engine__raise__a_ticket__popover__sub_category__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            const data = cloneDeep(formData);
                            setFormData({
                                ...data,
                                subCategory: val?.label
                            })
                        }} 
                        selectOptions={ruleEngineRaiseATicketCategoryReferenceData?.find((item) =>  item?.label === formData?.category)?.subCategories ?? []}
                        selectValue={{id: formData?.subCategory, label : formData?.subCategory}}
                     />
                </div>
            </div>
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Urgency*
                </div>
                <div>
                    <SelectDropDown 
                        selectDropDownId={'rule__engine__raise__a__ticket__side__popover__urgency__dropdown'} 
                        handleSelectOptionChange={(val)=>{
                            const data = cloneDeep(formData);
                            setFormData({
                                ...data,
                                urgency: val?.label
                            })
                        }} 
                        selectOptions={ruleEngineRaiseATicketUrgencyReferenceData ?? []}
                        selectValue={{id: formData?.urgency, label: formData?.urgency}}
                    />
                </div>
            </div>
            {/* <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Subject*
                </div>
                <div>
                <CustomInput 
                        inputValue={formData?.subject} 
                        inputFieldId={'rule__engine__raise__a__ticket__side__popover__subject__input'} 
                        isRequired={true}
                        handleInputChange={(event)=>{
                            const data = cloneDeep(formData); 
                            if(event?.target?.value.length > 150){
                                setSubjectError('Max length is 150 characters');
                            } else{
                                setFormData({
                                    ...data,
                                    subject: event?.target?.value
                                })
                                setSubjectError(null);
                            }                    
                        }}
                        errorMessage={subjectError}
                    />
                </div>
            </div> */}
            <div>
                <div className='rule__engine__raise__a__ticket__side__popover__row__label'>
                    Description
                </div>
                <div>
                    <CustomTextArea
                        inputValue={formData?.description} 
                        inputFieldId={'rule__engine__raise__a__ticket__description'}
                        isRequired={false}
                        isResizeable={true}
                        handleInputChange={(event)=>{
                            const data = cloneDeep(formData);
                            if(event?.target?.value.length > 500){
                                setDescriptionError('Max length is 500 characters');
                            }else {
                                setFormData({
                                    ...data,
                                    description: event?.target?.value
                                })
                                setDescriptionError(null);
                            }
                        }}
                        errorMessage={descriptionError}
                      />
                </div>

            </div>
         </div>
         <div className='rule__engine__if__then__else__side__popover__footer'>
          <CustomButtonGroup
            buttonsList={btnsList}
            buttonGroupClassName='button__group__footer' />
        </div>
        </>
    )
})

export default RaiseATicketPopOver;